.ql-container {
    border: none !important; /* Garante que a borda seja removida */
    box-shadow: none !important; /* Remove qualquer sombra aplicada */
}


.ql-toolbar {
    border: none !important; /* Remove a borda da barra de ferramentas */
    box-shadow: none !important; /* Remove sombras */
}

.ql-editor {
    border: none !important; /* Remove borda adicional na área de edição */
}
