.modal {

    ::-webkit-scrollbar {
        width: 7px;
    }
                        
    ::-webkit-scrollbar-track {
        color: #0f4a86;
        border-radius: 10px;
    }
                        
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #ffca00;
    }
}